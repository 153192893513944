import { fetchCategories } from './fetchCategories';
import { fetchTournaments } from './fetchTournaments';
import { fetchTournamentRating } from './fetchTournamentRating';
import { updateTournametBalance } from '~/src/features/tournaments/infrastructure/api/updateTournametBalance';
import { setParticipateInTournament } from '~/src/features/tournaments/infrastructure/api/setParticipateInTournament';

export const tournamentsApi = {
	fetchCategories,
	fetchTournaments,
	fetchTournamentRating,
	updateTournametBalance,
	setParticipateInTournament,
};
