import type { TournamentsDTO, TournamentsStatuses } from '../../core/types';
import type { FetchCategories } from '../../core/ports.output';
import { useApi } from '@/src/services/network';

export const toDomain = (data: TournamentsDTO): TournamentsStatuses => {
	const statusApi = data.result.filter.status;
	return Object.keys(statusApi).map(key => statusApi[key]);
};

export const fetchCategories: FetchCategories = async () => {
	const result = await useApi()<TournamentsDTO>(
		'/Tournaments/getTraderTournaments/',
	);
	return result.mapRight(v => toDomain(v));
};
