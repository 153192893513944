import type { TournamentsItems } from '../../core/types';
import type { FetchItems } from '../../core/ports.output';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type TournamentsDTO = Response<
	{
		filter: {
			status: {
				current: {
					current: 'Сейчас';
					code: 'current';
				};
				future: {
					name: 'Скоро';
					code: 'future';
				};
				past: {
					name: 'Архив';
					code: 'past';
				};
			};
		};
		items: {
			tournament_type: {
				id: string | number;
				name: string;
				code: string;
			};
			id: string;
			name: string;
			picture: string;
			tariff: {
				id: string;
				name: string;
				code: string;
				sum: string;
			};
			participation_price: number;
			date_start: DateString;
			date_end: DateString;
			description: string;
			prize_fund: number;
			prize_count: number;
			auto_award: string;
			prize_distribution: string[];
			start_balance: number;
			start_balance_update_sum: number;
			min_deal_sum: number;
			max_deal_sum: number;
			investments_limit: number;
			deals_limit: number;
			is_available: boolean | null;
		}[];
		count: number;
	}[]
>;

export const toDomain = (data: TournamentsDTO): TournamentsItems =>
	data.result.items.map(tournament => ({
		tournament_type: {
			id: tournament.tournament_type.id,
			name: tournament.tournament_type.name,
			code: tournament.tournament_type.name,
		},
		id: tournament.id,
		name: tournament.name,
		picture: tournament.picture,
		tariff: {
			id: tournament.tariff.id,
			name: tournament.tariff.name,
			code: tournament.tariff.code,
			sum: tournament.tariff.sum,
		},
		participation_price: tournament.participation_price,
		dateStart: tournament.date_start,
		dateEnd: tournament.date_end,
		description: tournament.description,
		prize_fund: tournament.prize_fund,
		prize_count: tournament.prize_count,
		auto_award: tournament.auto_award,
		prize_distribution: tournament.prize_distribution,
		start_balance: tournament.start_balance,
		start_balance_update_sum: tournament.start_balance_update_sum,
		min_deal_sum: tournament.min_deal_sum,
		max_deal_sum: tournament.max_deal_sum,
		investments_limit: tournament.investments_limit,
		deals_limit: tournament.deals_limit,
		is_available: tournament.is_available,
	}));

export const fetchTournaments: FetchItems = async (status) => {
	const result = await useApi()<TournamentsDTO>(
		'/Tournaments/getTraderTournaments/',
		{
			method: 'POST',
			body: {
				filter: { status: status },
			},
		},
	);
	return result.mapRight(v => toDomain(v));
};
