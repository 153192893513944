import { createEventHook } from '~/src/shared/lib';

type TerminalTournament = () => void;

const HOOK_NAME = 'terminal-tournament:updated';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[HOOK_NAME]: TerminalTournament;
	}
}

const {
	useOnEvent: useSubscribeTournamentUpdated,
	useTriggerEvent: useTriggerTournamentUpdate,
} = createEventHook(HOOK_NAME);

export const terminalTournamentBus = {
	useSubscribeTournamentUpdated,
	useTriggerTournamentUpdate,
};
