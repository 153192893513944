import { useApi } from '@/src/services/network';

import {
	toDomain,
} from '~/src/features/invoices/infrastructure/api/getInvoices';

export const updateTournametBalance = () => {
	const api = useApi();

	return async (tournamentId: string) =>
		(await api('/Tournaments/updateStartBalance/', {
			method: 'POST',
			body: {
				id: tournamentId,
			},
		})).mapRight(toDomain);
};
