<script setup lang="ts">
import { formatAmount, BaseIcon } from '@legacy-studio/library';
import { useTournamentsSelectedCategory } from '../infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';

const props = defineProps<{
	item: object;
}>();

defineEmits<{
	(event: 'participate'): void;
}>();

const currentCategory = computed(() => useTournamentsSelectedCategory());
const tournamentItem = computed(() => props.item);

const buttonText = computed(() => {
	if (currentCategory.value === 'past') {
		return 'Турнир завершен';
	}
	else if (tournamentItem.value.is_available === null) {
		return 'Вы уже в турнире';
	}
	else {
		return (
			'Участвовать за '
			+ formatAmount(tournamentItem.value.participation_price)
		);
	}
});
</script>

<template>
	<UiDarkBBButton
		:disabled="
			tournamentItem.is_available === 0 || tournamentItem.is_available === null
		"
		size="sm"
		:button-text="buttonText"
		class=" w-100"
		:left-icon-style="
			tournamentItem.is_available === 0 && currentCategory !== 'past'
				? 'base--fill--lock-hole'
				: ''
		"
		:filled-icon="false"
		:outline="
			tournamentItem.is_available === null
				|| tournamentItem.is_available === 0
				|| currentCategory === 'past'
		"
		@click="$emit('participate')"
	/>
	<div
		v-if="tournamentItem.is_available === 0 && currentCategory !== 'past'"
		class="flex flex-col gap-2.5 items-center text-center text-sm font-normal text-gray-500"
	>
		<p>Почему этот турнир недоступен</p>
		<BaseIcon
			class=""
			name="base--fill--question"
		/>
	</div>
</template>
