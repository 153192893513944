import type { SetParticipateTournament } from '~/src/features/tournaments/core/ports.input';
import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';
import { saveInvoices } from '~/src/features/invoices/infrastructure/store';
import { saveTournamentAvailable } from '~/src/features/tournaments/infrastructure/store';
import { terminalTournamentBus } from '~/src/features/tournaments/infrastructure/bus';

export const setParticipateTournament: SetParticipateTournament = async (
	tournamentId,
) => {
	const triggerTournamentUpdate = terminalTournamentBus.useTriggerTournamentUpdate();
	const result = await tournamentsApi.setParticipateInTournament(tournamentId);
	if (result.isRight()) {
		saveInvoices(result.value);
		saveTournamentAvailable(tournamentId);
		await triggerTournamentUpdate();
	}
};
